<template>
	<div class="header-container">
		<v-container>
			<div class="d-flex align-center justify-space-between main-header">
				<div
					class="header-logo"
					@click="() => doRoute('Home')"
					style="cursor: pointer"
				>
					<img src="../assets/img/logo_black.svg" />
				</div>
				<div v-if="!currentUser.email">
					<button class="light-btn" @click="() => doRoute('Login')">
						ログイン
					</button>
				</div>
				<div v-else>
					<v-menu transition="slide-y-transition" bottom offset-y right>
						<template v-slot:activator="{ on, attrs }">
							<v-btn dark v-bind="attrs" v-on="on" class="dropdown-btn">
								{{ currentUser.firstName + currentUser.lastName || "" }}
								<svg
									style="margin-left: 8px"
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4H12.796C12.9882 3.99984 13.1765 4.05509 13.3381 4.15914C13.4998 4.26319 13.628 4.41164 13.7075 4.58669C13.7869 4.76175 13.8142 4.956 13.7861 5.14618C13.758 5.33636 13.6757 5.51441 13.549 5.659L8.753 11.139C8.65914 11.2464 8.54339 11.3325 8.41351 11.3915C8.28364 11.4505 8.14265 11.481 8 11.481C7.85736 11.481 7.71637 11.4505 7.58649 11.3915C7.45662 11.3325 7.34087 11.2464 7.247 11.139V11.14Z"
										fill="#7F7F7F"
									/>
								</svg>
							</v-btn>
						</template>
						<v-list>
							<v-list-item @click="() => doRoute('Profile')">
								<v-list-item-content>
									<v-list-item-title>
										<svg
											style="margin-right: 10px"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M8 8C8.79565 8 9.55871 7.68393 10.1213 7.12132C10.6839 6.55871 11 5.79565 11 5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5C5 5.79565 5.31607 6.55871 5.87868 7.12132C6.44129 7.68393 7.20435 8 8 8ZM10 5C10 5.53043 9.78929 6.03914 9.41421 6.41421C9.03914 6.78929 8.53043 7 8 7C7.46957 7 6.96086 6.78929 6.58579 6.41421C6.21071 6.03914 6 5.53043 6 5C6 4.46957 6.21071 3.96086 6.58579 3.58579C6.96086 3.21071 7.46957 3 8 3C8.53043 3 9.03914 3.21071 9.41421 3.58579C9.78929 3.96086 10 4.46957 10 5ZM14 13C14 14 13 14 13 14H3C3 14 2 14 2 13C2 12 3 9 8 9C13 9 14 12 14 13ZM13 12.996C12.999 12.75 12.846 12.01 12.168 11.332C11.516 10.68 10.289 10 8 10C5.71 10 4.484 10.68 3.832 11.332C3.154 12.01 3.002 12.75 3 12.996H13Z"
												fill="#374955"
											/>
										</svg>
										プロフィール
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item @click="() => logout()">
								<v-list-item-content>
									<v-list-item-title>
										<svg
											style="margin-right: 10px"
											width="13"
											height="13"
											viewBox="0 0 13 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M1.83333 1C1.61232 1 1.40036 1.0878 1.24408 1.24408C1.0878 1.40036 1 1.61232 1 1.83333V11.1667C1 11.3877 1.0878 11.5996 1.24408 11.7559C1.40036 11.9122 1.61232 12 1.83333 12H4.5C4.77614 12 5 12.2239 5 12.5C5 12.7761 4.77614 13 4.5 13H1.83333C1.3471 13 0.880787 12.8068 0.536971 12.463C0.193154 12.1192 0 11.6529 0 11.1667V1.83333C0 1.3471 0.193154 0.880788 0.536971 0.536971C0.880788 0.193154 1.3471 0 1.83333 0H4.5C4.77614 0 5 0.223858 5 0.5C5 0.776142 4.77614 1 4.5 1H1.83333Z"
												fill="#374955"
											/>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M8.81295 2.81246C9.00821 2.6172 9.3248 2.6172 9.52006 2.81246L12.8534 6.1458C13.0487 6.34106 13.0487 6.65764 12.8534 6.8529L9.52006 10.1862C9.3248 10.3815 9.00821 10.3815 8.81295 10.1862C8.61769 9.99097 8.61769 9.67439 8.81295 9.47913L11.7927 6.49935L8.81295 3.51957C8.61769 3.32431 8.61769 3.00772 8.81295 2.81246Z"
												fill="#374955"
											/>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M4 6.5C4 6.22386 4.22386 6 4.5 6H12.5C12.7761 6 13 6.22386 13 6.5C13 6.77614 12.7761 7 12.5 7H4.5C4.22386 7 4 6.77614 4 6.5Z"
												fill="#374955"
											/>
										</svg>
										ログアウト
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
		</v-container>
		<div class="main-banner">
			<v-container style="height: 100%">
				<div
					class="d-flex align-center justify-space-between"
					style="height: 100%"
				>
					<div class="main-banner-left">
						<img src="../assets/img/golf-description.png" />
					</div>
					<div class="main-banner-right">
						<img src="../assets/img/golf-main.png" />
					</div>
				</div>
			</v-container>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/store/actions.type";
export default {
	computed: {
		...mapGetters(["currentUser"]),
	},
	methods: {
		doRoute(name) {
			if (name !== this.$route.name) {
				this.$router.push({
					name: name,
				});
			}
		},
		logout() {
			this.$store
				.dispatch(LOGOUT)
				.then((res, e) => {
					this.doRoute("Login");
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
</script>
<style>
@import "../assets/css/style.css";
.header-container {
	box-shadow: inset 0px -1px 0px #edf2f7;
	background-color: var(--white);
}
.main-header {
	height: 60px;
}
.header-logo {
	width: 125px;
}
.header-logo img {
	height: 100%;
	width: auto;
}
.main-banner {
	background-color: var(--black);
	height: 250px;
}
.main-banner-left img {
	width: auto;
	max-width: 360px;
}
.main-banner-right img {
	width: auto;
	max-width: 360px;
}
.dropdown-btn {
	background-color: transparent !important;
	color: var(--primary) !important;
	box-shadow: unset !important;
}
</style>
