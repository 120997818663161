<template>
	<v-app>
		<div class="auth-layout">
			<v-container class="pa-0">
				<v-row no-gutters>
					<v-col cols="12" sm="6">
						<div style="background-color: var(--grey); height: 100vh">
							<div class="auth-form d-flex align-center justify-center">
								<div class="auth-form-main px-10 py-4">
									<div class="text-center" style="margin-bottom: 96px">
										<img src="../assets/img/logo_white.svg" />
									</div>
									<div
										class="text-center font-extra-large white--text font-weight-bold mb-16"
									>
										パスワードの再設定
									</div>
									<v-row
										class="d-flex align-center justify-center white--text font-weight-bold"
										no-gutters
									>
										<v-col cols="12">新しいパスワード</v-col>
										<v-col cols="12">
											<v-text-field
												v-model="password"
												type="password"
												solo
												outlined
												flat
												:rules="passwordRules"
												placeholder="入力して下さい"
											></v-text-field>
										</v-col>
									</v-row>
									<v-row
										class="d-flex align-center justify-center white--text font-weight-bold"
										no-gutters
									>
										<v-col cols="12">新しいパスワード（確認）</v-col>
										<v-col cols="12">
											<v-text-field
												type="password"
												ref="password"
												v-model="confirmPassword"
												solo
												outlined
												flat
												:rules="confirmPasswordRules"
												placeholder="入力して下さい"
											></v-text-field>
										</v-col>
									</v-row>
									<v-row class="text-center" style="margin-bottom: 98px">
										<v-col cols="12">
											<button
												class="primary-btn"
												@click="() => resetPassword()"
											>
												変更する
											</button>
										</v-col>
									</v-row>
									<div class="auth-brand text-center">
										<img src="../assets/img/golf-brand.png" />
									</div>
									<div class="white--text" style="font-size: 14px;">R2 事業再構築</div>
								</div>
							</div>
						</div>
					</v-col>
					<v-col cols="12" sm="6" class="d-none d-sm-flex">
						<div class="d-flex" style="height: 100%">
							<div class="auth-side-image">
								<div class="auth-golf-main">
									<img src="../assets/img/golf-main.png" />
								</div>
								<div class="auth-golf-description">
									<img src="../assets/img/golf-description.png" />
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</v-app>
</template>
<script>
import { FORGOT_PASSWORD_VERIFY, RESET_PASSWORD } from "@/store/actions.type";

export default {
	name: "ResetPassword",
	data() {
		return {
			email: null,
			password: null,
			confirmPassword: null,
			passwordRules: [(v) => !!v || "パスワードが必要"],
			confirmPasswordRules: [
				(v) => !!v || "パスワードを再入力してください",
				(v) => v === this.password || "パスワードが一致していません",
			],
		};
	},
	async beforeMount() {
		const verification = this.$route.query.verification || "";
		if (verification) {
			await this.$store
				.dispatch(FORGOT_PASSWORD_VERIFY, { verification })
				.then((res, e) => {
					this.email = res.email || "";
				})
				.catch((e) => {
					console.log(e);
				});
		}
	},
	methods: {
		async resetPassword() {
			const validPassword = await this.$refs.password.validate();
			if (validPassword && this.email) {
				this.$store
					.dispatch(RESET_PASSWORD, {
						email: this.email,
						password: this.password,
					})
					.then((res, e) => {
						this.$router.push({
							name: 'ResetPasswordConfirmation',
						});
					})
					.catch((e) => {
            alert(e)
						console.log(e);
					});
			}
		},
	},
};
</script>
