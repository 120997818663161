export const CHECK_AUTH = "checkAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const UPDATE_USER_PROFILE = "updateUserProfile";
export const Recommend = "recommend";
export const UPDATE_RECOMMENDATION = 'updateRecommendation';
export const FORGOT_PASSWORD = 'forgotPassword';
export const FORGOT_PASSWORD_VERIFY = 'forgotPasswordVerify';
export const RESET_PASSWORD = 'resetPassword';
export const VERIFY_EMAIL = 'verifyEmail';
export const GET_ALL_USER = 'getAllUser';