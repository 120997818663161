<template>
	<v-app class="profile-page">
		<Header></Header>
		<div style="background-color: var(--white)">
			<v-container>
				<div
					class="text-center font-large font-weight-bold primary--text mt-16 mb-8"
				>
					レコメンドされたシャフト一覧
				</div>
				<div
					class="d-flex justify-end text-center white--text font-normal font-weight-bold"
				>
					<div
						class="py-1"
						style="
							width: 396px;
							background-color: var(--primary);
							cursor: pointer;
							border-radius: 4px;
						"
						@click="
							() =>
								this.$router.push({
									name: 'Profile',
									params: { isRecommending: true },
								})
						"
					>
						シャフト適合AI判定
					</div>
				</div>
				<div
					v-if="!currentUser.recommendationData ||!currentUser.recommendationData.length"
					class="text-center mt-12"
				>
					<img
						src="../assets/img/home.png"
						style="width: 365px; height: auto"
					/>
					<div class="mt-6 font-semi-large primary--text mb-2">
						レコメンドされたシャフトがありません。
					</div>
					<div
						class="font-small gold--text d-flex align-center justify-center mb-5"
						style="cursor: pointer"
						@click="
							() =>
								this.$router.push({
									name: 'Profile',
									params: { isRecommending: true },
								})
						"
					>
						<span class="mr-2">シャフト適合AI判定</span>
						<svg
							width="17"
							height="16"
							viewBox="0 0 17 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M11.64 8.75348L6.158 13.5495C5.513 14.1155 4.5 13.6555 4.5 12.7965L4.5 3.20448C4.49984 3.01223 4.55509 2.82402 4.65914 2.66236C4.76319 2.50071 4.91164 2.37248 5.08669 2.29303C5.26175 2.21357 5.456 2.18626 5.64618 2.21436C5.83636 2.24246 6.0144 2.32479 6.159 2.45148L11.639 7.24748C11.7464 7.34134 11.8325 7.45709 11.8915 7.58697C11.9505 7.71684 11.981 7.85783 11.981 8.00048C11.981 8.14312 11.9505 8.28411 11.8915 8.41399C11.8325 8.54386 11.7464 8.65962 11.639 8.75348L11.64 8.75348Z"
								fill="#C0A55C"
							/>
						</svg>
					</div>
				</div>
				<div v-else class="mt-12">
					<div
						v-for="(data, index) in currentUser.recommendationData"
						:key="index"
						class="d-flex align-center justify-space-between mb-8"
					>
						<div class="d-flex align-center">
							<div
								class="font-small font-weight-bold primary--text"
								style="margin-right: 64px"
							>
								0{{ index + 1 }}
							</div>
							<div>
								<img
									v-if="data.imgUrl"
									:src="getImgUrl(data.imgUrl)"
									alt="productImage"
									width="200px"
									height="100%"
								/>
							</div>
							<div
								class="font-normal font-weight-bold primary--text"
								style="margin-left: 56px"
							>
								{{ data.name }}
							</div>
						</div>
						<div class="d-flex align-center">
							<div
								class="d-flex algin-center justify-center font-normal font-weight-bold gold--text py-1 mr-8"
								style="
									width: 156px;
									background-color: var(--white);
									border: 1px solid var(--gold);
									border-radius: 4px;
									cursor: pointer;
								"
								@click="showProduct(index)"
							>
								詳細を見る
							</div>
							<div
								class="d-flex algin-center justify-center font-normal font-weight-bold white--text py-1"
								style="
									width: 156px;
									background-color: var(--gold);
									border: 1px solid var(--gold);
									border-radius: 4px;
									cursor: pointer;
								"
								@click="
									openLink(data.productUrl || 'https://planbgolf.base.shop')
								"
							>
								購入する
							</div>
						</div>
					</div>
					<div
						class="text-center pa-4 font-small font-weight-bold mb-7"
						style="
							background-color: #f9f3e8;
							border-radius: 8px;
							margin-top: 88px;
						"
					>
						<div class="primary--text">
							今回の診断結果についてのご質問やレンタルクラブをご希望の方は下記のホームページからお気軽にお問い合わせ下さい。
						</div>
						<div>
							<a
								class="gold--text"
								href="https://planbgolf.com/contact/"
								target="_blank"
								>https://planbgolf.com/contact/</a
							>
						</div>
					</div>
				</div>
				<div
					class="d-flex justify-end primary--text mb-10"
					style="font-size: 14px; line-height: 21px"
				>
					R2 事業再構築
				</div>
			</v-container>
		</div>
		<div v-if="isShowModal" class="loading-modal">
			<v-container style="margin-top: 100px">
				<div class="d-flex justify-center align-center">
					<ProductCard
						:productData="shownProductData"
						:isHomeScreen="true"
						@closeModal="onModalClose"
					/>
				</div>
			</v-container>
		</div>
	</v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header.vue";
import ProductCard from "../components/ProductCard.vue";

export default {
	name: "Home",
	data() {
		return {
			shownProductData: null,
			isShowModal: false,
		};
	},
	components: {
		Header,
		ProductCard,
	},
	computed: {
		...mapGetters(["currentUser"]),
	},
	beforeMount() {
		if (!this.currentUser.dataFilled) {
			this.doRoute("Profile");
		}
	},
	methods: {
		doRoute(name) {
			this.$router.push({
				name: name,
			});
		},
		showProduct(index) {
			this.shownProductData = this.currentUser.recommendationData[index];
			this.isShowModal = true;
		},
		onModalClose() {
			this.shownProductData = null;
			this.isShowModal = false;
		},
		openLink(url) {
			window.open(url, "_blank");
		},
		getImgUrl(img) {
			return require("../assets/img/products/" + img);
		},
	},
};
</script>
