<template>
	<v-app>
		<Header></Header>
		<v-container>
			<div
				class="text-center font-extra-large primary--text font-weight-bold mt-16 mb-8"
			>
				新規会員登録
			</div>
			<div class="register-container">
				<div class="register-form">
					<v-row class="d-flex align-center">
						<v-col cols="4">
							<v-row class="justify-content-between aligned-form-item">
								<v-col cols="9" class="font-normal font-weight-bold"
									>メールアドレス</v-col
								>
								<v-col cols="3" class="d-flex align-center text-center">
									<div class="required-item">必須</div>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-text-field
								ref="email"
								v-model="email"
								:value="email"
								solo
								outlined
								flat
								:rules="emailRules"
								placeholder="例：example@gmail.com"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row class="d-flex align-center">
						<v-col cols="4">
							<v-row class="justify-content-between aligned-form-item">
								<v-col cols="9" class="font-normal font-weight-bold"
									>氏名</v-col
								>
								<v-col cols="3" class="d-flex align-center text-center">
									<div class="required-item">必須</div>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-text-field
								ref="firstName"
								v-model="firstName"
								:value="firstName"
								solo
								outlined
								flat
								:rules="requiredFieldRules"
								placeholder="例：田中"
								hint="（性）"
								persistent-hint
							></v-text-field>
						</v-col>
						<v-col cols="4">
							<v-text-field
								ref="lastName"
								v-model="lastName"
								:value="lastName"
								solo
								outlined
								flat
								:rules="requiredFieldRules"
								placeholder="例：太郎"
								hint="（名）"
								persistent-hint
							></v-text-field>
						</v-col>
					</v-row>
					<v-row class="d-flex align-center">
						<v-col cols="4">
							<v-row class="justify-content-between aligned-form-item">
								<v-col cols="9" class="font-normal font-weight-bold"
									>氏名(カナ）</v-col
								>
								<v-col cols="3" class="d-flex align-center text-center">
									<div class="optional-item">任意</div>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-text-field
								v-model="firstNameKana"
								:value="firstNameKana"
								solo
								outlined
								flat
								placeholder="例：タナカ"
								hint="（セイ）"
								persistent-hint
							></v-text-field>
						</v-col>
						<v-col cols="4">
							<v-text-field
								v-model="lastNameKana"
								:value="lastNameKana"
								solo
								outlined
								flat
								placeholder="例：タロウ"
								hint="（メイ）"
								persistent-hint
							></v-text-field>
						</v-col>
					</v-row>
					<v-row class="d-flex align-center">
						<v-col cols="4">
							<v-row class="justify-content-between aligned-form-item">
								<v-col cols="9" class="font-normal font-weight-bold"
									>電話番号</v-col
								>
								<v-col cols="3" class="d-flex align-center text-center">
									<div class="optional-item">任意</div>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-text-field
								v-model="phone"
								:value="phone"
								type="number"
								solo
								outlined
								flat
								placeholder="例：09012345678"
								hint="※半角数字、ハイフン無しで入力"
								persistent-hint
							></v-text-field>
						</v-col>
					</v-row>
					<v-row class="d-flex align-center">
						<v-col cols="4">
							<v-row class="justify-content-between aligned-form-item">
								<v-col cols="9" class="font-normal font-weight-bold"
									>パスワード</v-col
								>
								<v-col cols="3" class="d-flex align-center text-center">
									<div class="required-item">必須</div>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-text-field
								v-model="password"
								type="password"
								solo
								outlined
								flat
								:rules="passwordRules"
								placeholder="半角英数記号5文字以上"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row class="d-flex align-center">
						<v-col cols="4">
							<v-row class="justify-content-between aligned-form-item">
								<v-col cols="9" class="font-normal font-weight-bold"></v-col>
								<v-col cols="3" class="d-flex align-center text-center">
									<div class="required-item">必須</div>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-text-field
								type="password"
								ref="password"
								v-model="confirmPassword"
								solo
								outlined
								flat
								:rules="confirmPasswordRules"
								placeholder="確認用"
							></v-text-field>
						</v-col>
					</v-row>
				</div>
			</div>
		</v-container>
		<div class="register-submit">
			<v-container>
				<div class="d-flex justify-end">
					<button class="primary-btn" @click="onSubmit">確認画面へ</button>
				</div>
			</v-container>
		</div>
	</v-app>
</template>
<script>
import { REGISTER } from "@/store/actions.type";
import Header from "@/components/Header";

export default {
	name: "Register",
	components: {
		Header,
	},
	data() {
		return {
			firstName: "",
			lastName: "",
			firstNameKana: "",
			lastNameKana: "",
			phone: "",
			email: "",
			password: null,
			confirmPassword: null,
			emailRules: [
				(v) =>
					!v ||
					/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
					"正しく入力してください",
			],
			requiredFieldRules: [(value) => !!value || "正しく入力してください."],
			passwordRules: [(v) => !!v || "パスワードが必要"],
			confirmPasswordRules: [
				(v) => !!v || "パスワードを再入力してください",
				(v) => v === this.password || "パスワードが一致していません",
			],
		};
	},
	methods: {
		async onSubmit() {
			const validEmail = await this.$refs.email.validate();
			const validFirstName = await this.$refs.firstName.validate();
			const validLastName = await this.$refs.lastName.validate();
			const validPassword = await this.$refs.password.validate();

			// valid is as an array of possible error messages. A length of 0 indicates valid input
			if (validEmail && validFirstName && validLastName && validPassword) {
				const registerData = {
					firstName: this.firstName,
					lastName: this.lastName,
					firstNameKana: this.firstNameKana,
					lastNameKana: this.lastNameKana,
					phone: this.phone,
					email: this.email,
					password: this.password,
				};
				this.$store
					.dispatch(REGISTER, registerData)
					.then((res, e) => {
						this.doRoute("RegisterConfirmation");
					})
					.catch((e) => {
						console.log(e);
						let err = "有効なメールアドレスを入力してください";
						if (
							Array.isArray(e.data.message) &&
							e.data.message[0].property === "password"
						) {
							err = "パスワードは5文字以上である必要があります";
						}
						this.$toast.error(err, {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: true,
							closeButton: "button",
							icon: true,
							rtl: false,
						});
					});
			}
		},
		doRoute(name) {
			this.$router.push({
				name: name,
			});
		},
	},
};
</script>
<style>
.register-container {
	padding-bottom: 40px;
}
.register-form {
	max-width: 1025px;
	margin: auto;
}
.aligned-form-item {
	margin-top: -38px !important;
}
.required-item {
	height: 21px;
	color: var(--white);
	background-color: var(--red);
	padding: 0px 8px !important;
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
	border-radius: 2px;
}
.optional-item {
	height: 21px;
	color: var(--white);
	background-color: var(--primary);
	padding: 0px 8px !important;
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
	border-radius: 2px;
}
.register-submit {
	height: 96px;
	width: 100%;
	background-color: #f1f1f1;
}
</style>
