<template lang="">
	<div class="font-normal font-weight-bold primary--text profile-form mt-6">
		<div class="font-weight-light my-8" style="font-size: 20px">7番アイアンのデータを入力してください。</div>
		<v-row class="justify-content-between">
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>キャリー [yds]</span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-text-field
						v-model="carry"
						:value="carry"
						height="48"
						type="number"
						solo
						outlined
						flat
					></v-text-field>
				</div>
			</v-col>
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>クラブ速度 [m/s]</span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-text-field
						v-model="clubSpeed"
						:value="clubSpeed"
						height="48"
						type="number"
						solo
						outlined
						flat
					></v-text-field>
				</div>
			</v-col>
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>左右ブレ幅 [yds]</span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-text-field
						v-model="lateralMovementWidth"
						:value="lateralMovementWidth"
						height="48"
						solo
						outlined
						flat
					></v-text-field>
				</div>
			</v-col>
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>スピン量 [rpm]</span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-text-field
						v-model="spinRate"
						:value="spinRate"
						height="48"
						type="number"
						solo
						outlined
						flat
					></v-text-field>
				</div>
			</v-col>
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>打ち出し角度（垂直）[ ' ]</span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-text-field
						v-model="verticalLaunchAngle"
						:value="verticalLaunchAngle"
						height="48"
						type="number"
						solo
						outlined
						flat
					></v-text-field>
				</div>
			</v-col>
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>打ち出し方向左右 [ ' ] </span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-text-field
						v-model="horizontalLaunchAngle"
						:value="horizontalLaunchAngle"
						height="48"
						type="number"
						solo
						outlined
						flat
					></v-text-field>
				</div>
			</v-col>
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>ミート率</span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-text-field
						v-model="meatRate"
						:value="meatRate"
						height="48"
						type="number"
						solo
						outlined
						flat
					></v-text-field>
				</div>
			</v-col>
			<v-col cols="5">
				<v-row class="justify-space-between align-center">
					<v-col cols="12">
						<div class="d-flex align-center">
							<span>高さ[{{selectedUnitLength}}]</span>
						</div>
						<v-row>
							<v-col cols="8">
								<v-text-field
									v-model="ballHeight"
									:value="ballHeight"
									height="48"
									type="number"
									solo
									outlined
									flat
									hide-details
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-select
									:items="unitLengths"
									label="長さの単位"
									v-model="selectedUnitLength"
								></v-select>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" style="padding-top: 0px !important">
						<div class="primary--text font-weight-regular" style="font-size: 14px">
							※単位は、右側にあるリストから選んでください。
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>
<script>
export default {
	data() {
		return {
			lateralMovementWidth: null,
			spinRate: null,
			verticalLaunchAngle: null,
			meatRate: null,
			carry: null,
			horizontalLaunchAngle: null,
			clubSpeed: null,
			ballHeight: null,
			selectedUnitLength: 'ft',
			unitLengths: ['ft', 'm', 'yds']
		};
	},
	props: {
		data: Object,
	},
	beforeMount() {
		this.lateralMovementWidth = this.data.lateralMovementWidth || null;
		this.spinRate = this.data.spinRate || null;
		this.verticalLaunchAngle = this.data.verticalLaunchAngle || null;
		this.meatRate = this.data.meatRate || null;
		this.carry = this.data.carry || null;
		this.horizontalLaunchAngle = this.data.horizontalLaunchAngle || null;
		this.clubSpeed = this.data.clubSpeed || null;
		this.ballHeight = this.data.ballHeight || null;
	},
	watch: {
		lateralMovementWidth(newVal) {
			this.$emit("dataUpdated", {
				lateralMovementWidth: Number(newVal),
			});
		},
		spinRate(newVal) {
			this.$emit("dataUpdated", {
				spinRate: Number(newVal),
			});
		},
		verticalLaunchAngle(newVal) {
			this.$emit("dataUpdated", {
				verticalLaunchAngle: Number(newVal),
			});
		},
		meatRate(newVal) {
			this.$emit("dataUpdated", {
				meatRate: Number(newVal),
			});
		},
		carry(newVal) {
			this.$emit("dataUpdated", {
				carry: Number(newVal),
			});
		},
		horizontalLaunchAngle(newVal) {
			this.$emit("dataUpdated", {
				horizontalLaunchAngle: Number(newVal),
			});
		},
		clubSpeed(newVal) {
			this.$emit("dataUpdated", {
				clubSpeed: Number(newVal),
			});
		},
		ballHeight(newVal) {
			this.$emit("dataUpdated", {
				ballHeight: this.convertLengthToFt(this.selectedUnitLength, Number(newVal)),
			});
		},
	},
	methods: {
		convertLengthToFt(type, length) {
			if (type === 'yds') return length * 3
			else if (type === 'm') return Math.round(length * 3.28084);
			else return length
		}
	},
};
</script>
<style></style>
