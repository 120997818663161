<template>
	<v-app>
		<Header></Header>
		<v-container>
			<div class="text-center" style="margin-top: 128px">
				<svg
					width="64"
					height="64"
					viewBox="0 0 64 64"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clip-path="url(#clip0_307_5061)">
						<path
							d="M64 31.9973C64 40.8363 60.4178 48.8356 54.6219 54.6266C48.8369 60.4175 40.8329 64 32 64C14.3288 64 0 49.6754 0 31.9973C0 23.1637 3.58221 15.1644 9.37809 9.37344C15.1631 3.58252 23.1672 0 32 0C40.8329 0 48.8369 3.58252 54.6219 9.37344C60.4178 15.1644 64 23.1637 64 31.9973Z"
							fill="url(#paint0_linear_307_5061)"
						/>
						<path
							d="M50.6964 13.2876L27.3057 51.1631C26.8314 51.9265 25.7354 51.9647 25.212 51.234L13.3258 34.5864C12.9006 33.9866 13.593 33.2287 14.2255 33.5995L25.4846 40.2029L49.6005 12.4806C50.1512 11.8481 51.1381 12.5733 50.6964 13.2876Z"
							fill="white"
						/>
					</g>
					<defs>
						<linearGradient
							id="paint0_linear_307_5061"
							x1="0"
							y1="32.0002"
							x2="63.9994"
							y2="32.0002"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#4FC646" />
							<stop offset="1" stop-color="#3FB06B" />
						</linearGradient>
						<clipPath id="clip0_307_5061">
							<rect width="64" height="64" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<div
				class="text-center font-extra-large primary--text font-weight-bold my-6"
			>
				会員登録完了
			</div>
			<div
				class="text-center font-normal primary--text mx-auto mb-12"
				style="max-width: 240px"
			>
				ありがとうございます。会員登録が完了しました。
			</div>
			<div class="text-center">
				<button class="primary-btn" @click="() => doRoute('Login')">
					ログイン画面へ
				</button>
			</div>
		</v-container>
	</v-app>
</template>
<script>
import Header from "@/components/Header";
import { VERIFY_EMAIL } from "@/store/actions.type";

export default {
	name: "AccountConfirmation",
	components: {
		Header,
	},
	data() {
		return {};
	},
	async beforeMount() {
		const verification = this.$route.query.verification || "";
		if (verification) {
			await this.$store
				.dispatch(VERIFY_EMAIL, { verification })
				.then((res, e) => {})
				.catch((e) => {
					alert(e)
					console.log(e);
				});
		}
	},
	methods: {
		doRoute(name) {
			this.$router.push({
				name: name,
			});
		},
	},
};
</script>
<style></style>
