const SHAFT_DICTIONARY = [
	{
		name: "BCS 65 (専用シャフト)",
		image: "1_BCS65.png",
		url: "https://planbgolf.base.shop/items/70319666",
		flex: "S",
		weight: "65g",
		kickPt: "元",
	},
	{
		name: "BCS 85 (専用シャフト)",
		image: "2_BCS85.png",
		url: "https://planbgolf.base.shop/items/70318739",
		flex: "S",
		weight: "80.4g",
		kickPt: "中",
	},
	{
		name: "Fire Express Premium Version i55",
		image: "3_Premium_I55.jpg",
		url: "https://planbgolf.base.shop",
		flex: "R相当",
		weight: "56g",
		kickPt: "中",
	},
	{
		name: "Fire Express Spirits i75",
		image: "4_Fire Express Spirits i75.png",
		url: "https://planbgolf.base.shop/items/70377043",
		flex: "R相当",
		weight: "75g",
		kickPt: "中",
	},
	{
		name: "Fire Express TT-45i",
		image: "5_FE_TT45i.jpg",
		url: "https://planbgolf.base.shop",
		flex: "R相当",
		weight: "47g",
		kickPt: "先",
	},
	{
		name: "Fujikura MCI 100",
		image: "6_MCI_silver_100-S.jpg",
		url: "https://planbgolf.base.shop",
		flex: "S",
		weight: "104g",
		kickPt: "中",
	},
	{
		name: "Fujikura MCI 60",
		image: "7_MCI_silver_60-R.jpg",
		url: "https://planbgolf.base.shop",
		flex: "R",
		weight: "65g",
		kickPt: "中",
	},
	{
		name: "Fujikura MCI Black 100",
		image: "8_MCI_black_100-S_a.jpg",
		url: "https://planbgolf.base.shop",
		flex: "S",
		weight: "99g",
		kickPt: "中",
	},
	{
		name: "GRAND BASSARA i40",
		image: "dumpProduct.png",
		url: "https://planbgolf.base.shop",
		flex: "L相当",
		weight: "41g",
		kickPt: "中",
	},
	{
		name: "KBS $-TAPER 120",
		image: "10_s_taper_chrome_120.png",
		url: "https://planbgolf.base.shop",
		flex: "S",
		weight: "120g",
		kickPt: "元",
	},
	{
		name: "KBS $-TAPER LITE 100",
		image: "11_s_taper_lite_chrome_100.png",
		url: "https://planbgolf.base.shop",
		flex: "S相当",
		weight: "100g",
		kickPt: "中",
	},
	{
		name: "KBS TGI 80",
		image: "12_TGI_80.png",
		url: "https://planbgolf.base.shop/items/70316916",
		flex: "S相当",
		weight: "80g",
		kickPt: "元",
	},
	{
		name: "KBS TOUR 130",
		image: "13_kbs_tour_130_x.png",
		url: "https://planbgolf.base.shop",
		flex: "X",
		weight: "130g",
		kickPt: "元",
	},
	{
		name: "N.S. PRO 850GH",
		image: "14_850GH(S).jpg",
		url: "https://planbgolf.base.shop",
		flex: "R, S",
		weight: "87g(R), 91g(S)",
		kickPt: "中",
	},
	{
		name: "N.S. PRO 950GH",
		image: "15_950GH-S.jpg",
		url: "https://planbgolf.base.shop",
		flex: "S",
		weight: "97g",
		kickPt: "中",
	},
	{
		name: "N.S. PRO 950GH neo",
		image: "16_950GHneo-S.jpg",
		url: "https://planbgolf.base.shop",
		flex: "R",
		weight: "97g, 94.5g",
		kickPt: "中",
	},
	{
		name: "N.S. PRO MODUS TOUR 125",
		image: "17_Modus_TOUR125_long.jpg",
		url: "https://planbgolf.base.shop/items/70317123",
		flex: "R",
		weight: "127.5g",
		kickPt: "元",
	},
	{
		name: "N.S. PRO MODUS(3) 105",
		image: "18_Modus3_TOUR105_S.jpg",
		url: "https://planbgolf.base.shop/items/70319515",
		flex: "R",
		weight: "103g",
		kickPt: "元",
	},
	{
		name: "N.S. PRO MODUS(3) 115",
		image: "19_Modus3_TOUR115_S.jpg",
		url: "https://planbgolf.base.shop/items/70319515",
		flex: "S, X",
		weight: "118.5g(S), 119.5(X)",
		kickPt: "元",
	},
	{
		name: "N.S. PRO MODUS(3) 120",
		image: "20_Modus3_TOUR120_S.jpg",
		url: "https://planbgolf.base.shop/items/70319515",
		flex: "S, X, R",
		weight: "114g(S), 120g(X), 111g(R)",
		kickPt: "中",
	},
	{
		name: "N.S. PRO MODUS(3) 130",
		image: "21_Modus3_TOUR130全体.jpg",
		url: "https://planbgolf.base.shop/items/70319515",
		flex: "S",
		weight: "124g",
		kickPt: "中",
	},
	{
		name: "N.S. PRO ZELOS６",
		image: "22_NSPRO_Zelos6.jpg",
		url: "https://planbgolf.base.shop/items/70356720",
		flex: "R",
		weight: "68.5g",
		kickPt: "先",
	},
	{
		name: "N.S. PRO ZELOS７",
		image: "23_ZELOS7(S).jpg",
		url: "https://planbgolf.base.shop/items/70356720",
		flex: "R, S",
		weight: "74g(R), 77.5g(S)",
		kickPt: "先",
	},
	{
		name: "TRUE TEMPER SteelFiber i95",
		image: "24_SteelFiber i95.jpg",
		url: "https://planbgolf.base.shop",
		flex: "S",
		weight: "95g",
		kickPt: "中",
	},
];

export default SHAFT_DICTIONARY;
