<template>
	<v-app class="profile-page">
		<Header></Header>
		<div style="background-color: var(--white); min-height: 590px">
			<v-container>
				<v-tabs
					v-model="tab"
					background-color="transparent"
					color="gold"
					grow
					height="52"
					class="mt-8"
					style="width: 840px"
				>
					<v-tab>
						<div
							:class="
								tab === 0
									? 'd-flex align-center font-semi-large pb-4 gold--text'
									: 'd-flex align-center font-semi-large pb-4'
							"
						>
							<div class="mr-2 d-flex align-center">
								<svg
									v-if="tab !== 0"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 12C12.9946 12 13.9484 11.6049 14.6517 10.9017C15.3549 10.1984 15.75 9.24456 15.75 8.25C15.75 7.25544 15.3549 6.30161 14.6517 5.59835C13.9484 4.89509 12.9946 4.5 12 4.5C11.0054 4.5 10.0516 4.89509 9.34835 5.59835C8.64509 6.30161 8.25 7.25544 8.25 8.25C8.25 9.24456 8.64509 10.1984 9.34835 10.9017C10.0516 11.6049 11.0054 12 12 12ZM14.5 8.25C14.5 8.91304 14.2366 9.54893 13.7678 10.0178C13.2989 10.4866 12.663 10.75 12 10.75C11.337 10.75 10.7011 10.4866 10.2322 10.0178C9.76339 9.54893 9.5 8.91304 9.5 8.25C9.5 7.58696 9.76339 6.95107 10.2322 6.48223C10.7011 6.01339 11.337 5.75 12 5.75C12.663 5.75 13.2989 6.01339 13.7678 6.48223C14.2366 6.95107 14.5 7.58696 14.5 8.25ZM19.5 18.25C19.5 19.5 18.25 19.5 18.25 19.5H5.75C5.75 19.5 4.5 19.5 4.5 18.25C4.5 17 5.75 13.25 12 13.25C18.25 13.25 19.5 17 19.5 18.25ZM18.25 18.245C18.2487 17.9375 18.0575 17.0125 17.21 16.165C16.395 15.35 14.8612 14.5 12 14.5C9.1375 14.5 7.605 15.35 6.79 16.165C5.9425 17.0125 5.7525 17.9375 5.75 18.245H18.25Z"
										fill="#374955"
									/>
								</svg>
								<svg
									v-else
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.75 19.5C5.75 19.5 4.5 19.5 4.5 18.25C4.5 17 5.75 13.25 12 13.25C18.25 13.25 19.5 17 19.5 18.25C19.5 19.5 18.25 19.5 18.25 19.5H5.75ZM12 12C12.9946 12 13.9484 11.6049 14.6517 10.9017C15.3549 10.1984 15.75 9.24456 15.75 8.25C15.75 7.25544 15.3549 6.30161 14.6517 5.59835C13.9484 4.89509 12.9946 4.5 12 4.5C11.0054 4.5 10.0516 4.89509 9.34835 5.59835C8.64509 6.30161 8.25 7.25544 8.25 8.25C8.25 9.24456 8.64509 10.1984 9.34835 10.9017C10.0516 11.6049 11.0054 12 12 12V12Z"
										fill="#AD965D"
									/>
								</svg>
							</div>
							<div>パーソナルデータ</div>
						</div>
					</v-tab>
					<v-tab>
						<div
							:class="
								tab === 1
									? 'd-flex align-center font-semi-large pb-4 gold--text'
									: 'd-flex align-center font-semi-large pb-4'
							"
						>
							<div class="mr-2 d-flex align-center">
								<svg
									v-if="tab !== 1"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clip-path="url(#clip0_306_4311)">
										<path
											d="M10.75 3.76758V7.00008H4.5C4.16848 7.00008 3.85054 7.13177 3.61612 7.36619C3.3817 7.60062 3.25 7.91856 3.25 8.25008V13.2501C3.25 13.5816 3.3817 13.8995 3.61612 14.134C3.85054 14.3684 4.16848 14.5001 4.5 14.5001H10.75V22.0001H13.25V14.5001H17.665C17.8482 14.5 18.0292 14.4597 18.1951 14.3819C18.361 14.3041 18.5077 14.1909 18.625 14.0501L21.0413 11.1501C21.1348 11.0378 21.186 10.8962 21.186 10.7501C21.186 10.6039 21.1348 10.4624 21.0413 10.3501L18.625 7.45008C18.5077 7.3093 18.361 7.19602 18.1951 7.11825C18.0292 7.04049 17.8482 7.00015 17.665 7.00008H13.25V3.76758C13.25 3.43606 13.1183 3.11812 12.8839 2.88369C12.6495 2.64927 12.3315 2.51758 12 2.51758C11.6685 2.51758 11.3505 2.64927 11.1161 2.88369C10.8817 3.11812 10.75 3.43606 10.75 3.76758ZM17.665 8.25008L19.7475 10.7501L17.665 13.2501H4.5V8.25008H17.665Z"
											fill="#374955"
										/>
									</g>
									<defs>
										<clipPath id="clip0_306_4311">
											<rect
												width="20"
												height="20"
												fill="white"
												transform="translate(2 2)"
											/>
										</clipPath>
									</defs>
								</svg>
								<svg
									v-else
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.1163 2.88356C10.8818 3.11793 10.7501 3.43582 10.75 3.76731V6.99981H4.5C4.16848 6.99981 3.85054 7.13151 3.61612 7.36593C3.3817 7.60035 3.25 7.91829 3.25 8.24981V13.2498C3.25 13.5813 3.3817 13.8993 3.61612 14.1337C3.85054 14.3681 4.16848 14.4998 4.5 14.4998H10.75V21.9998H13.25V14.4998H17.665C17.8482 14.4997 18.0292 14.4594 18.1951 14.3816C18.361 14.3039 18.5077 14.1906 18.625 14.0498L21.0413 11.1498C21.1348 11.0375 21.186 10.896 21.186 10.7498C21.186 10.6037 21.1348 10.4621 21.0413 10.3498L18.625 7.44981C18.5077 7.30903 18.361 7.19575 18.1951 7.11799C18.0292 7.04022 17.8482 6.99988 17.665 6.99981H13.25V3.76731C13.2499 3.52012 13.1766 3.2785 13.0393 3.07299C12.9019 2.86748 12.7067 2.7073 12.4783 2.61271C12.2499 2.51813 11.9987 2.49337 11.7562 2.54158C11.5138 2.58979 11.2911 2.7088 11.1163 2.88356Z"
										fill="#AD965D"
									/>
								</svg>
							</div>
							<div>改善したいポイント</div>
						</div>
					</v-tab>
					<v-tab>
						<div
							:class="
								tab === 2
									? 'd-flex align-center font-semi-large pb-4 gold--text'
									: 'd-flex align-center font-semi-large pb-4'
							"
						>
							<div class="mr-2 d-flex align-center">
								<svg
									v-if="tab !== 2"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.4785 10.0842C10.9618 10.0842 11.3535 9.69245 11.3535 9.2092C11.3535 8.72595 10.9618 8.3342 10.4785 8.3342C9.99527 8.3342 9.60352 8.72595 9.60352 9.2092C9.60352 9.69245 9.99527 10.0842 10.4785 10.0842Z"
										fill="#374955"
									/>
									<path
										d="M13.4785 10.0059C13.9618 10.0059 14.3535 9.61411 14.3535 9.13086C14.3535 8.64761 13.9618 8.25586 13.4785 8.25586C12.9953 8.25586 12.6035 8.64761 12.6035 9.13086C12.6035 9.61411 12.9953 10.0059 13.4785 10.0059Z"
										fill="#374955"
									/>
									<path
										d="M11.9922 8C12.4754 8 12.8672 7.60825 12.8672 7.125C12.8672 6.64175 12.4754 6.25 11.9922 6.25C11.5089 6.25 11.1172 6.64175 11.1172 7.125C11.1172 7.60825 11.5089 8 11.9922 8Z"
										fill="#374955"
									/>
									<path
										d="M15.1785 17H8.77852C8.33852 17 7.97852 17.36 7.97852 17.8C7.97852 18.24 8.33852 18.6 8.77852 18.6H9.57852C10.4585 18.6 11.1785 19.32 11.1785 20.2V21H12.7785V20.2C12.7785 19.32 13.4985 18.6 14.3785 18.6H15.1785C15.6185 18.6 15.9785 18.24 15.9785 17.8C15.9785 17.36 15.6185 17 15.1785 17Z"
										fill="#374955"
									/>
									<path
										d="M17.4 10C17.4 12.9823 14.9823 15.4 12 15.4C9.01766 15.4 6.6 12.9823 6.6 10C6.6 7.01766 9.01766 4.6 12 4.6C14.9823 4.6 17.4 7.01766 17.4 10Z"
										stroke="#374955"
										stroke-width="1.2"
									/>
								</svg>

								<svg
									v-else
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M18 10C18 13.3137 15.3137 16 12 16C8.68629 16 6 13.3137 6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10Z"
										fill="#AD965D"
									/>
									<path
										d="M10.4785 10.0842C10.9618 10.0842 11.3535 9.69245 11.3535 9.2092C11.3535 8.72595 10.9618 8.3342 10.4785 8.3342C9.99527 8.3342 9.60352 8.72595 9.60352 9.2092C9.60352 9.69245 9.99527 10.0842 10.4785 10.0842Z"
										fill="white"
									/>
									<path
										d="M13.4785 10.0059C13.9618 10.0059 14.3535 9.61411 14.3535 9.13086C14.3535 8.64761 13.9618 8.25586 13.4785 8.25586C12.9953 8.25586 12.6035 8.64761 12.6035 9.13086C12.6035 9.61411 12.9953 10.0059 13.4785 10.0059Z"
										fill="white"
									/>
									<path
										d="M11.9922 8C12.4754 8 12.8672 7.60825 12.8672 7.125C12.8672 6.64175 12.4754 6.25 11.9922 6.25C11.5089 6.25 11.1172 6.64175 11.1172 7.125C11.1172 7.60825 11.5089 8 11.9922 8Z"
										fill="white"
									/>
									<path
										d="M15.1785 17H8.77852C8.33852 17 7.97852 17.36 7.97852 17.8C7.97852 18.24 8.33852 18.6 8.77852 18.6H9.57852C10.4585 18.6 11.1785 19.32 11.1785 20.2V21H12.7785V20.2C12.7785 19.32 13.4985 18.6 14.3785 18.6H15.1785C15.6185 18.6 15.9785 18.24 15.9785 17.8C15.9785 17.36 15.6185 17 15.1785 17Z"
										fill="#AD965D"
									/>
								</svg>
							</div>
							<div>スウィングデータ</div>
						</div>
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab">
					<v-tab-item>
						<PersonalData
							:data="personalData"
							@dataUpdated="personalDataChanged"
						/>
					</v-tab-item>
					<v-tab-item>
						<PersonalPoint
							:data="personalPoint"
							@dataUpdated="personalPointChanged"
						/>
					</v-tab-item>
					<v-tab-item>
						<PersonalSwingData
							:data="personalSwingData"
							@dataUpdated="personalSwingDataChanged"
						/>
					</v-tab-item>
				</v-tabs-items>
			</v-container>
		</div>
		<div class="profile-button-group">
			<v-container>
				<div class="d-flex justify-space-between">
					<div class="d-flex align-center">
						<div
							v-if="tab !== 0"
							class="d-flex justify-center align-center"
							style="cursor: pointer"
						>
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_408_4194)">
									<path
										d="M17.5 1.25C17.8315 1.25 18.1495 1.3817 18.3839 1.61612C18.6183 1.85054 18.75 2.16848 18.75 2.5V17.5C18.75 17.8315 18.6183 18.1495 18.3839 18.3839C18.1495 18.6183 17.8315 18.75 17.5 18.75H2.5C2.16848 18.75 1.85054 18.6183 1.61612 18.3839C1.3817 18.1495 1.25 17.8315 1.25 17.5V2.5C1.25 2.16848 1.3817 1.85054 1.61612 1.61612C1.85054 1.3817 2.16848 1.25 2.5 1.25H17.5ZM2.5 0C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V17.5C0 18.163 0.263392 18.7989 0.732233 19.2678C1.20107 19.7366 1.83696 20 2.5 20H17.5C18.163 20 18.7989 19.7366 19.2678 19.2678C19.7366 18.7989 20 18.163 20 17.5V2.5C20 1.83696 19.7366 1.20107 19.2678 0.732233C18.7989 0.263392 18.163 0 17.5 0H2.5Z"
										fill="#AD965D"
									/>
									<path
										d="M12.7563 15.57C12.8661 15.5206 12.9593 15.4406 13.0248 15.3394C13.0902 15.2383 13.125 15.1204 13.125 15V5C13.125 4.87952 13.0902 4.76161 13.0248 4.66044C12.9594 4.55928 12.8661 4.47917 12.7563 4.42978C12.6464 4.38038 12.5245 4.3638 12.4055 4.38202C12.2864 4.40025 12.1751 4.4525 12.085 4.5325L6.46 9.5325C6.39391 9.59114 6.34101 9.66313 6.30478 9.74371C6.26854 9.8243 6.24981 9.91165 6.24981 10C6.24981 10.0884 6.26854 10.1757 6.30478 10.2563C6.34101 10.3369 6.39391 10.4089 6.46 10.4675L12.085 15.4675C12.1751 15.5475 12.2864 15.5997 12.4055 15.6179C12.5246 15.636 12.6464 15.6194 12.7563 15.57Z"
										fill="#AD965D"
									/>
								</g>
								<defs>
									<clipPath id="clip0_408_4194">
										<rect width="20" height="20" fill="white" />
									</clipPath>
								</defs>
							</svg>
							<span
								class="ml-2 gold--text font-weight-bold"
								@click="handleBack()"
								>戻る</span
							>
						</div>
					</div>
					<div class="d-flex justify-end" style="width: 500px">
						<div
							v-if="isChanged"
							class="gold--text mr-4 ma-auto"
							style="cursor: pointer"
							@click="() => resetForm()"
						>
							キャンセル
						</div>
						<button
							class="primary-btn"
							:disabled="isDisabled"
							@click="handleNext()"
						>
							{{ buttonText }}
						</button>
					</div>
				</div>
			</v-container>
		</div>
		<div v-if="isLoading" class="loading-modal">
			<div class="d-flex justify-center align-center" style="height: 100%">
				<div>
					<img src="../assets/img/loading.gif" alt="loading" width="250" />
					<div class="text-center white--text font-weight-bold font-semi-large">
						判定中・・・
					</div>
				</div>
			</div>
		</div>
		<div v-if="isShowRecommendation" class="loading-modal">
			<v-container style="margin-top: 100px; max-width: 1200px">
				<div class="font-large font-weight-bold white--text mb-4">
					適合シャフトリスト
				</div>
				<div class="font-normal white--text mb-8">
					AIはあなたのパーソナルデータ（身長、体重など）スイングデータからヘッド重量250g、フレックス{{
						recommendationData.length ? recommendationData[0].flex : ""
					}}、シャフト重{{
						recommendationData.length ? recommendationData[0].weight : ""
					}}、キックポイント
					{{
						recommendationData.length ? recommendationData[0].kickPt : ""
					}}をベストの組み合わせとして推奨します。
				</div>
				<div class="d-flex justify-space-between align-center">
					<div
						v-for="(product, index) in recommendationData"
						:key="index"
						style="max-width: 343px"
					>
						<ProductCard :productData="product" />
					</div>
				</div>
				<div class="mt-10 d-flex justify-center">
					<button
						class="py-3 font-normal font-weight-bold gold--text"
						style="
							width: 350px;
							background-color: var(--white);
							border: 1px solid var(--gold);
							border-radius: 4px;
						"
						@click="() => doRoute('Home')"
					>
						ホームへ戻る
					</button>
				</div>
			</v-container>
		</div>
	</v-app>
</template>

<style lang="css">
@import "../assets/css/style.css";
.profile-page {
	background-color: #f1f1f1 !important;
	position: relative;
}
.loading-modal {
	position: absolute;
	z-index: 10000;
	height: 100%;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.6);
}
</style>

<script>
import Header from "@/components/Header.vue";
import PersonalData from "../components/PersonalData.vue";
import PersonalPoint from "../components/PersonalPoint.vue";
import PersonalSwingData from "../components/PersonalSwingData.vue";
import ProductCard from "../components/ProductCard.vue";
import {
	UPDATE_USER_PROFILE,
	UPDATE_RECOMMENDATION,
} from "@/store/actions.type";
import { RECOMMEND_API_URL } from "../common/config";
import { mapGetters } from "vuex";
import axios from "axios";
import SHAFT_DICTIONARY from "../common/shaftDictionary";

export default {
	name: "Profile",
	data() {
		return {
			tab: null,
			isChanged: false,
			isDisabled: false,
			isLoading: false,
			personalData: {
				gender: "",
				weight: null,
				height: null,
				gripStrength: null,
				armLength: null,
				averageFlightDistance: null,
				averageSevenIronDistance: null,
				currentClubName: null,
				currentShaftInUse: null,
			},
			personalSwingData: {
				lateralMovementWidth: null,
				spinRate: null,
				verticalLaunchAngle: null,
				meatRate: null,
				carry: null,
				horizontalLaunchAngle: null,
				clubSpeed: null,
				ballHeight: null,
			},
			personalPoint: {
				distanceAndDirection: null,
				ballisticTendencyLeftRight: null,
				ballisticTendencyHightLow: null,
			},
			buttonText: null,
			isShowRecommendation: false,
			recommendationData: [],
		};
	},
	components: {
		Header,
		PersonalData,
		PersonalPoint,
		PersonalSwingData,
		ProductCard,
	},
	computed: {
		...mapGetters(["currentUser"]),
	},
	beforeMount() {
		if (!this.currentUser.dataFilled) {
			this.isDisabled = true;
		}
		this.initializeValue();
	},
	watch: {
		tab(newVal) {
			if (newVal !== 2) {
				this.buttonText = "次へ";
			} else {
				this.$route.params.isRecommending
					? (this.buttonText = "判定する")
					: (this.buttonText = "保存する");
			}
		},
	},
	methods: {
		initializeValue() {
			for (const key of Object.keys(this.personalData)) {
				this.personalData[key] = this.currentUser[key];
			}
			for (const key of Object.keys(this.personalSwingData)) {
				this.personalSwingData[key] = this.currentUser[key];
			}
			for (const key of Object.keys(this.personalPoint)) {
				this.personalPoint[key] = this.currentUser[key];
			}
		},
		handleBack() {
			if (this.tab > 0) {
				this.tab--;
			}
		},
		handleNext() {
			if (this.tab < 2) {
				this.tab++;
			} else if (this.tab === 2) {
				this.openLoadingModal();
				this.updateData();
				this.runRecommendation();
			}
		},
		openLoadingModal() {
			this.isLoading = true;
		},
		closeLoadingModal() {
			this.isLoading = false;
		},
		updateData() {
			this.$store
				.dispatch(UPDATE_USER_PROFILE, {
					email: this.currentUser.email,
					...this.personalData,
					...this.personalPoint,
					...this.personalSwingData,
				})
				.then((res, e) => {
					if (!this.$route.params.isRecommending) {
						this.doRoute("Home");
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		runRecommendation() {
			setTimeout(() => {
				const recommendationParams = {
					身長: this.personalData.height || "",
					体重: this.personalData.weight || "",
					筋力: this.personalData.gripStrength || "",
					腕の長さ: this.personalData.armLength || "",
					キャリー: this.personalSwingData.carry || "",
					左右ブレ幅: this.personalSwingData.lateralMovementWidth || "",
					クラブ速度: this.personalSwingData.clubSpeed || "",
					スピン量: this.personalSwingData.spinRate || "",
					ミート率: this.personalSwingData.meatRate || "",
					打ち出し角_垂直方向: this.personalSwingData.verticalLaunchAngle || "",
					高さ: this.personalSwingData.ballHeight || "",
				};
				axios
					.get(RECOMMEND_API_URL, { params: recommendationParams })
					.then((res, e) => {
						if (res.data && res.data.shaft_recommend.length) {
							for (const [index, data] of res.data.shaft_recommend.entries()) {
								const shaftDictData = SHAFT_DICTIONARY.find(
									(item) => item.name === data.name
								);
								this.recommendationData.push({
									index: index,
									imgUrl: shaftDictData.image || "",
									productUrl: shaftDictData.url || "https://planbgolf.base.shop",
									name: data.name,
									flex: data.stiffness || "R",
									weight: data.weight + 'g' || "39,5g",
									kickPt: data.kickpoint || "MID - LOW",
								});
							}
							this.$store
								.dispatch(UPDATE_RECOMMENDATION, {
									email: this.currentUser.email,
									recommendationData: this.recommendationData,
								})
								.catch((e) => {
									console.log(e);
								});
						}
						this.isLoading = false;
						this.isShowRecommendation = true;
					})
					.catch((e) => {
						console.log(e);
					});
			}, 2000);
		},
		personalDataChanged(val) {
			// this.isChanged = true;
			for (const [key, value] of Object.entries(val)) {
				this.personalData[key] = value;
				if (key === "weight" || key === "height") {
					if (this.personalData.weight && this.personalData.height) {
						this.isDisabled = false;
					} else {
						this.isDisabled = true;
					}
				}
			}
		},
		personalPointChanged(val) {
			// this.isChanged = true;
			for (const [key, value] of Object.entries(val)) {
				this.personalPoint[key] = value;
			}
		},
		personalSwingDataChanged(val) {
			// this.isChanged = true;
			for (const [key, value] of Object.entries(val)) {
				this.personalSwingData[key] = value;
			}
		},
		resetForm() {
			this.initializeValue();
			// this.isChanged = false;
		},
		doRoute(name) {
			this.$router.push({
				name: name,
			});
		},
	},
};
</script>
