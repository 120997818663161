<template>
	<v-app>
		<div class="auth-layout">
			<v-container class="pa-0">
				<v-row no-gutters>
					<v-col cols="12" sm="6">
						<div style="background-color: var(--grey); height: 100vh">
							<div class="auth-form d-flex align-center justify-center">
								<div class="auth-form-main px-10 py-4">
									<div class="text-center" style="margin-bottom: 96px">
										<img src="../assets/img/logo_white.svg" />
									</div>
									<div
										class="text-center font-extra-large white--text font-weight-bold mb-16"
									>
										パスワードの再設定
									</div>
									<div class="font-normal text-center white--text mb-16">
										<div v-if="!isSent">
											パスワード再設定用URLを送信しますので、メールアドレスを入力して下さい。
										</div>
										<div v-else>
											<div class="mb-6">
												ご入力いただいたメールアドレス宛に、パスワード再設定メールを送信しました。メール本文にあるリンクをクリックし、パスワードを変更してください。
											</div>
											<div>
												もし、メールが届いていない場合は、迷惑メール等をご確認ください。
											</div>
										</div>
									</div>
									<v-row
										v-if="!isSent"
										class="d-flex align-center justify-center white--text font-weight-bold"
										no-gutters
									>
										<v-col cols="12">メールアドレス</v-col>
										<v-col cols="12">
											<input
												class="auth-input"
												type="text"
												placeholder="メールアドレスを入力して下さい"
												v-on:keyup.enter="onSubmit"
												v-model="email"
											/>
										</v-col>
									</v-row>
									<v-row
										v-if="!isSent"
										class="text-center mt-16"
										style="margin-bottom: 98px"
									>
										<v-col cols="5">
											<button
												class="secondary-btn"
												@click="() => doRoute('Login')"
											>
												戻る
											</button>
										</v-col>
										<v-col cols="7">
											<button
												class="primary-btn"
												:disabled="isDisabled"
												@click="onSubmit"
											>
												送信
											</button>
										</v-col>
									</v-row>
									<v-row v-else class="text-center" style="margin-bottom: 98px">
										<v-col cols="12">
											<button
												class="primary-btn"
												@click="() => doRoute('Login')"
											>
												ログイン画面へ
											</button>
										</v-col>
									</v-row>
									<div class="auth-brand text-center">
										<img src="../assets/img/golf-brand.png" />
									</div>
									<div class="white--text" style="font-size: 14px;">R2 事業再構築</div>
								</div>
							</div>
						</div>
					</v-col>
					<v-col cols="12" sm="6" class="d-none d-sm-flex">
						<div class="d-flex" style="height: 100%">
							<div class="auth-side-image">
								<div class="auth-golf-main">
									<img src="../assets/img/golf-main.png" />
								</div>
								<div class="auth-golf-description">
									<img src="../assets/img/golf-description.png" />
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</v-app>
</template>
<script>
import { FORGOT_PASSWORD } from "@/store/actions.type";

export default {
	name: "ForgotPassword",
	data() {
		return {
			email: null,
			isDisabled: true,
			isSent: false,
		};
	},
	watch: {
		email(newVal) {
			if (newVal) {
				this.isDisabled = false;
			}
		},
	},
	methods: {
		onSubmit() {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
				var email = this.email;
				this.$store
					.dispatch(FORGOT_PASSWORD, { email })
					.then((res, e) => {
						this.isSent = true;
					})
					.catch((e) => {
						console.log(e);
						this.$toast.error("有効なメールアドレスを入力してください", {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: true,
							closeButton: "button",
							icon: true,
							rtl: false,
						});
					});
			} else {
				this.$toast.error("有効なメールアドレスを入力してください", {
					position: "top-right",
					timeout: 5000,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: true,
					closeButton: "button",
					icon: true,
					rtl: false,
				});
			}
		},
		doRoute(name) {
			this.$router.push({
				name: name,
			});
		},
	},
};
</script>
