import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        black: "#080302",
        grey: "#434345",
        grey2: "#7F7F7F",
        gold: "#C0A55C",
        red: "#FF0000",
        primary: "#374955",
        secondary: "#AAAAAA",
        white: "#FFFFFF",
      },
    },
  },
})
