<template>
	<v-app>
		<div class="auth-layout">
			<v-container class="pa-0">
				<v-row no-gutters>
					<v-col cols="12" sm="6">
						<div style="background-color: var(--grey); height: 100vh">
							<div class="auth-form d-flex align-center justify-center">
								<div class="auth-form-main px-10 py-4">
									<div class="text-center mb-10">
										<img src="../assets/img/logo_white.svg" />
									</div>
									<div
										class="grey2--text text-center mb-10 font-normal font-weight-medium"
									>
										入力されたデータから<span class="white--text">AI</span
										>があなたに<span class="white--text">「最適なクラブ」</span
										>を推奨する<span class="white--text"
											>フィッティングシステム</span
										>です。
									</div>
									<v-row
										class="d-flex align-center justify-center mb-10 white--text font-weight-bold"
										no-gutters
									>
										<v-col cols="12" lg="3">メールアドレス</v-col>
										<v-col cols="12" lg="9">
											<input
												class="auth-input"
												type="text"
												v-on:keyup.enter="onSubmit"
												v-model="email"
											/>
										</v-col>
									</v-row>
									<v-row
										class="d-flex align-center justify-center mb-10 white--text font-weight-bold"
										no-gutters
									>
										<v-col cols="12" lg="3">パスワード</v-col>
										<v-col cols="12" lg="9">
											<input
												class="auth-input"
												type="password"
												v-on:keyup.enter="onSubmit"
												v-model="password"
											/>
										</v-col>
									</v-row>
									<div class="text-right gold--text mb-10">
										<a
											class="gold--text"
											@click="() => doRoute('ForgotPassword')"
											style="text-decoration: none"
											>パスワードを忘れた場合</a
										>
									</div>
									<div class="text-center" style="margin-bottom: 16px">
										<button
											class="primary-btn"
											:disabled="isDisabled"
											@click="onSubmit"
										>
											ログイン
										</button>
									</div>
									<div class="text-center">
										<div
											class="white--text font-extra-small"
											style="margin-bottom: 8px"
										>
											もしくは
										</div>
										<div
											class="white--text font-small"
											style="margin-bottom: 100px"
										>
											新規会員登録は<a
												@click="() => doRoute('Register')"
												class="gold--text"
												style="text-decoration: none"
												>こちら</a
											>
										</div>
									</div>
									<div class="auth-brand text-center">
										<img src="../assets/img/golf-brand.png" />
									</div>
									<div class="white--text" style="font-size: 14px;">R2 事業再構築</div>
								</div>
							</div>
						</div>
					</v-col>
					<v-col cols="12" sm="6" class="d-none d-sm-flex">
						<div class="d-flex" style="height: 100%">
							<div class="auth-side-image">
								<div class="auth-golf-main">
									<img src="../assets/img/golf-main.png" />
								</div>
								<div class="auth-golf-description">
									<img src="../assets/img/golf-description.png" />
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</v-app>
</template>
<script>
import { mapState } from "vuex";
import { LOGIN } from "@/store/actions.type";

export default {
	name: "Login",
	data() {
		return {
			email: null,
			password: null,
			isDisabled: true,
		};
	},
	watch: {
		email(newVal) {
			if (newVal && this.password) {
				this.isDisabled = false;
			}
		},
		password(newVal) {
			if (newVal && this.email) {
				this.isDisabled = false;
			}
		},
	},
	methods: {
		onSubmit() {
			var email = this.email;
			var password = this.password;
			this.$store
				.dispatch(LOGIN, { email, password })
				.then((res, e) => {
					this.doRoute("Home");
				})
				.catch((e) => {
					let err = "無効なアカウント"
					if (!Array.isArray(e)) {
						err = e
					}
					this.$toast.error(err, {
						position: "top-right",
						timeout: 5000,
						closeOnClick: true,
						pauseOnFocusLoss: true,
						pauseOnHover: true,
						draggable: true,
						draggablePercent: 0.6,
						showCloseButtonOnHover: false,
						hideProgressBar: true,
						closeButton: "button",
						icon: true,
						rtl: false,
					});
				});
		},
		doRoute(name) {
			this.$router.push({
				name: name,
			});
		},
	},
	computed: {
		...mapState({
			errors: (state) => state.auth.errors,
		}),
	},
};
</script>
