import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import RegisterConfirmation from "../views/RegisterConfirmation.vue";
import AccountConfirmation from "../views/AccountConfirmation.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Profile from "../views/Profile.vue";
import Home from "../views/Home.vue";
import ResetPassword from "../views/ResetPassword.vue";
import ResetPasswordConfirmation from "../views/ResetPasswordConfirmation.vue";
import UserManagement from "../views/UserManagement.vue";
import UserDetail from "../views/UserDetail.vue";
import store from "../store";
import { CHECK_AUTH } from "../store/actions.type";

Vue.use(VueRouter);

const guard = function (to, from, next) {
	new Promise((resolve, reject) => {
		store
			.dispatch(CHECK_AUTH)
			.then((e) => {
				if (e) {
					if (e.roles.includes("admin")) {
						next("/user-management");
					} else {
						next();
					}
				} else {
					next("login");
				}
			})
			.catch((e) => {
				next("/login");
			});
	});
};

const checkIsLoggedIn = function (to, from, next) {
	new Promise((resolve, reject) => {
		store
			.dispatch(CHECK_AUTH)
			.then((e) => {
				if (e) {
					next("/");
				} else {
					next();
				}
			})
			.catch((e) => {
				next();
			});
	});
};

const checkIsAdminLoggedIn = function (to, from, next) {
	new Promise((resolve, reject) => {
		store
			.dispatch(CHECK_AUTH)
			.then((e) => {
				if (e) {
					if (e.roles.includes("admin")) {
						next();
					} else {
						next("/");
					}
				} else {
					next("/login");
				}
			})
			.catch((e) => {
				next();
			});
	});
};

const routes = [
	{
		path: "/login",
		name: "Login",
		component: Login,
		beforeEnter: (to, from, next) => {
			checkIsLoggedIn(to, from, next);
		},
	},
	{
		path: "/register",
		name: "Register",
		component: Register,
	},
	{
		path: "/register-confirmation",
		name: "RegisterConfirmation",
		component: RegisterConfirmation,
	},
	{
		path: "/account-confirmation",
		name: "AccountConfirmation",
		component: AccountConfirmation,
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: ForgotPassword,
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: ResetPassword,
	},
	{
		path: "/reset-password-confirmation",
		name: "ResetPasswordConfirmation",
		component: ResetPasswordConfirmation,
	},
	{
		path: "/user-management",
		name: "UserManagement",
		component: UserManagement,
		beforeEnter: (to, from, next) => {
			checkIsAdminLoggedIn(to, from, next);
		},
	},
	{
		path: "/user-detail",
		name: "UserDetail",
		component: UserDetail,
		beforeEnter: (to, from, next) => {
			checkIsAdminLoggedIn(to, from, next);
		},
	},
	{
		path: "/profile",
		name: "Profile",
		component: Profile,
		beforeEnter: (to, from, next) => {
			guard(to, from, next);
		},
	},
	{
		path: "/",
		name: "Home",
		component: Home,
		beforeEnter: (to, from, next) => {
			guard(to, from, next);
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
