import ApiService from "../common/api.service";
import JwtService from "../common/jwt.service";
import {
	LOGIN,
	LOGOUT,
	REGISTER,
	CHECK_AUTH,
	UPDATE_USER,
	UPDATE_USER_PROFILE,
	UPDATE_RECOMMENDATION,
	Recommend,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_VERIFY,
	RESET_PASSWORD,
	VERIFY_EMAIL,
	GET_ALL_USER,
} from "./actions.type";
import { SET_AUTH, PURGE_AUTH, SET_ERROR } from "./mutations.type";
// ApiService.init();

const state = {
	errors: null,
	user: {},
	isAuthenticated: !!JwtService.getToken(),
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	},
};

const actions = {
	[LOGIN](context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post("user/login", credentials)
				.then(({ data }) => {
					context.commit(SET_AUTH, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response);
					let error = response.data.message || "Incorrect email or password";
					reject(error);
				});
		});
	},
	// [Recommend](context, credentials) {
	// 	return new Promise((resolve) => {
	// 		ApiService.post("recommend", credentials)
	// 			.then(({ data }) => {
	// 				// console.log(data);
	// 				context.commit(SET_AUTH, data);
	// 				resolve(data);
	// 			})
	// 			.catch(({ response }) => {
	// 				context.commit(SET_ERROR, response.data.errors);
	// 			});
	// 	});
	// },
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
	[REGISTER](context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post("user", credentials)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[CHECK_AUTH](context) {
		if (JwtService.getToken()) {
			ApiService.setHeader();
			return new Promise((resolve, reject) => {
				ApiService.get("user/data")
					.then(({ data }) => {
						context.commit(SET_AUTH, data);
						resolve(data);
					})
					.catch(({ response }) => {
						context.commit(SET_ERROR, response);
						reject(response);
					});
			});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[UPDATE_USER](context, payload) {
		const { email, username, password, image, bio } = payload;
		const user = {
			email,
			username,
			bio,
			image,
		};
		if (password) {
			user.password = password;
		}

		return ApiService.put("user", user).then(({ data }) => {
			context.commit(SET_AUTH, data);
			return data;
		});
	},
	[UPDATE_USER_PROFILE](context, payload) {
		return ApiService.put("user/data", payload).then(({ data }) => {
			return data;
		});
	},
	[UPDATE_RECOMMENDATION](context, payload) {
		return ApiService.put("user/data/recommendation", payload).then(
			({ data }) => {
				return data;
			}
		);
	},
	[FORGOT_PASSWORD](context, payload) {
		return ApiService.post("user/forgot-password", payload).then(({ data }) => {
			return data;
		});
	},
	[FORGOT_PASSWORD_VERIFY](context, payload) {
		return ApiService.post("user/forgot-password-verify", payload).then(
			({ data }) => {
				return data;
			}
		);
	},
	[RESET_PASSWORD](context, payload) {
		return ApiService.post("user/reset-password", payload).then(({ data }) => {
			return data;
		});
	},
	[VERIFY_EMAIL](context, payload) {
		return ApiService.post("user/verify-email", payload).then(({ data }) => {
			return data;
		});
	},
	[GET_ALL_USER](context, payload) {
		return ApiService.get(
			`user/all-data?page=${payload.page}&perPage=${payload.perPage}`
		).then(({ data }) => {
			return data;
		});
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTH](state, user) {
		state.isAuthenticated = true;
		state.user = user;
		state.errors = {};
		if (state.user.accessToken) {
			JwtService.saveToken(state.user.accessToken);
		}
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.errors = {};
		JwtService.destroyToken();
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
