<template>
	<v-app>
		<Header></Header>
		<v-container>
			<div
				class="text-center font-extra-large primary--text font-weight-bold mt-16 mb-4"
			>
				メールをご確認ください
			</div>
			<div
				class="text-center font-normal primary--text mx-auto mb-10"
				style="max-width: 700px"
			>
				ご入力いただいたメールアドレス宛に、会員登録完了メールを送信しました。メール本文にあるリンクをクリックし会員登録を完了させてください。もし、メールが届いていない場合は、迷惑メール等をご確認ください。
			</div>
			<div class="text-center">
				<button class="primary-btn" @click="() => doRoute('Login')">
					ログイン画面へ
				</button>
			</div>
		</v-container>
	</v-app>
</template>
<script>
import Header from "@/components/Header";

export default {
	name: "RegisterConfirmation",
	components: {
		Header,
	},
	data() {
		return {};
	},
	methods: {
		doRoute(name) {
			this.$router.push({
				name: name,
			});
		},
	},
};
</script>
<style></style>
