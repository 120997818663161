<template lang="">
	<div class="font-normal font-weight-bold primary--text profile-form mt-6">
		<div class="font-weight-regular mb-8">
			次の項目についてプルダウンから選んでください
		</div>
		<v-row class="justify-content-between">
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>飛距離と方向性</span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-select
						:value="distanceAndDirection"
						:items="distanceAndDirections"
						outlined
						@change="distanceAndDirectionUpdated"
					></v-select>
				</div>
			</v-col>
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>弾道傾向（左右）</span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-select
						:value="ballisticTendencyLeftRight"
						:items="ballisticTendencyLeftRights"
						outlined
						@change="ballisticTendencyLeftRightUpdated"
					></v-select>
				</div>
			</v-col>
		</v-row>
		<v-row class="justify-content-between">
			<v-col cols="5">
				<div class="d-flex align-center">
					<span>弾道傾向（高低）</span>
				</div>
				<div class="d-flex font-weight-regular mt-2">
					<v-select
						:value="ballisticTendencyHightLow"
						:items="ballisticTendencyHightLows"
						outlined
						@change="ballisticTendencyHightLowUpdated"
					></v-select>
				</div>
			</v-col>
		</v-row>
	</div>
</template>
<script>
export default {
	data() {
		return {
			distanceAndDirection: null,
			distanceAndDirections: [
				"方向性よりも飛距離を伸ばしたい",
				"飛距離よりも方向性を安定させたい",
				"飛距離、方向性ともに今のままで良い",
			],
			ballisticTendencyLeftRight: null,
			ballisticTendencyLeftRights: [
				"スライスが出やすいので直したい",
				"今のままで良い",
				"フックが出やすいので直したい",
			],
			ballisticTendencyHightLow: null,
			ballisticTendencyHightLows: [
				"弾道を高くしたい",
				"今のままで良い",
				"弾道を低くしたい",
			],
		};
	},
	props: {
		data: Object,
	},
	beforeMount() {
		this.distanceAndDirection = this.data.distanceAndDirection || null;
		this.ballisticTendencyLeftRight =
			this.data.ballisticTendencyLeftRight || null;
		this.ballisticTendencyHightLow =
			this.data.ballisticTendencyHightLow || null;
	},
	methods: {
		distanceAndDirectionUpdated(newVal) {
			this.$emit("dataUpdated", {
				distanceAndDirection: newVal,
			});
		},
		ballisticTendencyLeftRightUpdated(newVal) {
			this.$emit("dataUpdated", {
				ballisticTendencyLeftRight: newVal,
			});
		},
		ballisticTendencyHightLowUpdated(newVal) {
			this.$emit("dataUpdated", {
				ballisticTendencyHightLow: newVal,
			});
		},
	},
};
</script>
<style></style>
