<template>
	<div class="stick">
		<div
			class="detail-header d-flex align-center justify-space-between pa-4"
			style="border-radius: 8px 8px 0px 0px"
		>
			<div class="font-small font-weight-bold primary--text">
				マッチング結果
			</div>
			<div class="percent font-normal font-weight-bold white--text">
				{{
					productData.index === 0
						? "1st"
						: productData.index === 1
						? "2nd"
						: "3rd"
				}}
			</div>
		</div>
		<div class="detail-body">
			<div class="stick-properties">
				<div class="stick-img">
					<a href="http://planb-japan.com" target="_blank">
						<img
							src="../assets/img/head1.png"
							alt="stick"
							width="auto"
							height="90px"
						/>
					</a>
				</div>
				<div class="stick-parameter font-small font-weight-bold primary--text">
					Break Common Sense
				</div>
			</div>
			<div class="stick-properties" style="border: 0px !important">
				<div class="stick-img">
					<a
						:href="
							productData.productUrl
								? productData.productUrl
								: 'https://planbgolf.base.shop'
						"
						target="_blank"
					>
						<img
							v-if="productData.imgUrl"
							:src="getImgUrl(productData.imgUrl)"
							alt="productImage"
							width="100%"
						/>
						<img
							v-else
							src="../assets/img/dumpProduct.png"
							alt="productImage"
							width="100%"
							height="80px"
						/>
					</a>
				</div>
				<div class="stick-parameter">
					<div class="name-stick font-small font-weight-bold primary--text">
						{{ productData.name }}
					</div>
					<div class="param">
						<span class="key">Flex </span>
						<span class="value">{{ productData.flex }}</span>
					</div>
					<div class="param">
						<span class="key">Weight </span>
						<span class="value">{{ productData.weight }}</span>
					</div>
					<div class="param">
						<span class="key">Kick Pt.</span>
						<span class="value">{{ productData.kickPt }}</span>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="!isHomeScreen"
			class="mx-6 mb-8 py-3 text-center font-normal font-weight-bold white--text"
			style="background-color: var(--gold); border-radius: 4px"
		>
			<button>
				<a
					:href="productData.productUrl || 'https://planbgolf.base.shop'"
					target="_blank"
					class="white--text"
					style="text-decoration: none"
					>購入する</a
				>
			</button>
		</div>
		<div v-else class="d-flex justify-space-between align-center px-6 mb-8">
			<div
				class="py-1 text-center font-normal font-weight-bold gold--text"
				style="
					background-color: var(--white);
					border-radius: 4px;
					border: 1px solid var(--gold);
					width: 147px;
				"
			>
				<button @click="closeModal">閉じる</button>
			</div>
			<div
				class="py-1 text-center font-normal font-weight-bold white--text"
				style="background-color: var(--gold); border-radius: 4px; width: 147px"
			>
				<button>
					<a
						:href="productData.productUrl || '#'"
						target="_blank"
						class="white--text"
						style="text-decoration: none"
						>購入する</a
					>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		productData: Object,
		isHomeScreen: Boolean,
	},
	methods: {
		BackToInfor() {
			this.$router.push("/");
		},
		openDetail(url) {
			window.open(url, "_blank");
		},
		closeModal() {
			this.$emit("closeModal");
		},
		getImgUrl(img) {
			return require("../assets/img/products/" + img);
		},
	},
};
</script>
<style lang="css">
@import "../assets/css/style.css";
</style>
