import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { CHECK_AUTH } from "./store/actions.type";
import ApiService from "./common/api.service";
import vuetify from './plugins/vuetify';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


const options = {
    draggable: false
  }
  
Vue.use(Toast, options);
Vue.config.productionTip = false

ApiService.init();

// Ensure we checked auth before each page load.
// router.beforeEach((to, from, next) =>
//   Promise.all([store.dispatch(CHECK_AUTH)]).then(next)
// );
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')